import React from 'react';
import axios from 'axios';

const DeveloperRoadmap = () => {
  const [technicalCourses, setTechnicalCourses] = React.useState([]);

  React.useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get('https://kavi-api-kavi-sekhon-276359988851.us-central1.run.app/api/data/');
      setTechnicalCourses(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const getUniqueCategories = () => {
    const categories = technicalCourses.map((course) => course.category);
    return [...new Set(categories)];
  };

  return (
    <div className="max-w-[90%] mx-auto px-4 py-8">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-6">
        {getUniqueCategories().map((category) => (
          <div key={category} className="bg-white rounded-lg shadow-sm hover:shadow-md transition-shadow p-6 border border-gray-100">
            <h2 className="text-xl font-semibold mb-4 text-gray-900 text-center">{category}</h2>
            <div className="space-y-2">
              {technicalCourses
                .filter(course => course.category === category)
                .map((course, index) => (
                  <div key={index} className="group flex items-start space-x-2">
                    <span className="text-gray-400 mt-1.5">•</span>
                    {course.link ? (
                      <a
                        href={course.link}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="block text-blue-600 hover:text-blue-800 group-hover:translate-x-1 transition-transform"
                      >
                        {course.article}
                      </a>
                    ) : (
                      <span className="block text-gray-700">
                        {course.article}
                      </span>
                    )}
                  </div>
                ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const Home = () => {
  return (
    <div className="min-h-screen bg-gray-50">
      <DeveloperRoadmap />
    </div>
  );
};

export default Home;