import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { FaGithub, FaEnvelope, FaCog } from "react-icons/fa";

export default function NavBar() {
  const [isScrolled, setIsScrolled] = useState(false);

  // Add scroll event listener
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <nav className={`sticky top-0 bg-white z-50 transition-all duration-200 ${
      isScrolled 
        ? 'border-b border-gray-200 shadow-[0_2px_8px_rgba(0,0,0,0.08)]' 
        : 'border-b border-gray-200'
    }`}>
      {/* Top navigation bar */}
      <div className="max-w-7xl mx-auto px-4">
        <div className="flex items-center justify-between h-16">
          <div className="flex items-center">
            <Link to="/" className="text-xl text-gray-800 hover:text-gray-600 transition-colors">
              Kavi Sekhon
            </Link>
          </div>
          <div className="flex items-center space-x-6">
            <a 
              href="https://github.com/kavisek" 
              target="_blank" 
              rel="noopener noreferrer"
              className="text-gray-600 hover:text-gray-900 transition-colors flex items-center space-x-2"
            >
              <FaGithub className="text-xl" />
              <span>GitHub</span>
            </a>
            <a 
              href="mailto:info@kavisekhon.com"
              className="text-gray-600 hover:text-gray-900 transition-colors flex items-center space-x-2"
            >
              <FaEnvelope className="text-xl" />
              <span>Email</span>
            </a>
            <FaCog className="text-gray-600 hover:text-gray-900 cursor-pointer transition-colors" />
          </div>
        </div>
      </div>
      
      {/* Category tabs */}
      <div className="max-w-7xl mx-auto px-4">
        <div className="flex items-center space-x-8 h-12 -mb-px">
          <Link 
            to="/" 
            className="text-sm text-gray-600 h-full flex items-center border-b-2 border-transparent hover:border-gray-400 hover:text-gray-900 transition-all"
          >
            Home
          </Link>
          {/* <Link 
            to="/projects" 
            className="text-sm text-gray-600 h-full flex items-center border-b-2 border-transparent hover:border-gray-400 hover:text-gray-900 transition-all"
          >
            Projects
          </Link>
          <Link 
            to="/blog" 
            className="text-sm text-gray-600 h-full flex items-center border-b-2 border-transparent hover:border-gray-400 hover:text-gray-900 transition-all"
          >
            Blog
          </Link> */}
        </div>
      </div>
    </nav>
  );
}
