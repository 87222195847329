import { BrowserRouter as Router } from "react-router-dom";
import Home from "./routes/home";
import About from "./routes/about";
import Fonts from "./routes/fonts";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";

export default function App() {
  return (
    <div className="min-h-screen bg-gray-50">
      <NavBar />
      <main className="max-w-[90%] mx-auto px-4 pt-6">
        <Home />
      </main>
    </div>
  );
}

export function AboutLayout() {
  return (
    <div>
      <NavBar></NavBar>
      <About />
      <Footer></Footer>
    </div>
  );
}

export function FontsLayout() {
  return (
    <div>
      <NavBar></NavBar>
      <Fonts />
      <Footer></Footer>
    </div>
  );
}

